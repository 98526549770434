import withStyles from 'isomorphic-style-loader/withStyles';
import { useEffect, useState } from 'react';
import { Button, Checkbox, Icon, Popup } from 'semantic-ui-react';

import darkModeProps from './darkModeProps.json';
import s from './DarkModeToggle.scss';

const DarkModeToggle = () => {
  const [darkReaderAvailable, setDarkReaderAvailable] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (process.env.BROWSER && window.App?.darkReader) {
        setDarkReaderAvailable(true);
        setIsDarkMode(window.App.darkReader.isEnabled());
      }
    }, 0);
  }, []);

  // Function to switch between dark and light mode. It also saves the current
  // mode to localStorage, so that it can be restored on page reload.
  const switchMode = (ev, data) => {
    if (data.checked) {
      document.getElementById('layout')!.classList.add('dark');
      window.App.darkReader.enable(darkModeProps);
    } else {
      document.getElementById('layout')!.classList.remove('dark');
      window.App.darkReader.disable();
    }
    localStorage.setItem('darkmode', data.checked!.toString());
    setIsDarkMode(data.checked!);
  };

  // The actual toggle component. It is only rendered if the Dark Reader
  // component is available.
  const CheckboxExampleSlider = () =>
    darkReaderAvailable ? (
      <div className={s.root}>
        <span>
          <Popup
            content="Here comes the sun, and I say, it's alright..."
            position="bottom center"
            mouseEnterDelay={1000}
            trigger={
              <Button
                basic
                icon
                color="black"
                onClick={ev => switchMode(ev, { checked: false })}
              >
                <Icon name="sun" color="yellow" />
              </Button>
            }
          />
          <Checkbox
            slider
            className={isDarkMode ? s.toggleDark : s.toggleLight}
            checked={isDarkMode}
            onChange={switchMode}
          />
          <Popup
            content="Hello darkness my old friend..."
            position="bottom center"
            mouseEnterDelay={1000}
            trigger={
              <Button
                basic
                icon
                color="black"
                onClick={ev => switchMode(ev, { checked: true })}
              >
                <Icon name="moon" color="yellow" />
              </Button>
            }
          />
        </span>
      </div>
    ) : null;

  return CheckboxExampleSlider();
};

export default withStyles(s)(DarkModeToggle);
