// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Spk1y .raDjm{background-color:#fff;border:1px solid #e0b4b4;color:#9f3a38}.Spk1y .PH18A{-webkit-touch-callout:none;background-color:#fff6f6;color:#9f3a38;cursor:pointer;margin:1rem 1rem .75rem!important;padding:0!important;-webkit-user-select:none;user-select:none}.Spk1y .Sjs4O{margin-right:2.5rem!important}.Spk1y .InJyV{color:#9f3a38;font-size:15.2px;font-size:.95rem;text-transform:none}.Spk1y .DzDBM{font-size:13.6px;font-size:.85rem;margin:0;text-align:left;white-space:pre-wrap}.Spk1y .TYaT1{margin-bottom:0;margin-top:2rem!important;white-space:pre-wrap}.zofSH{background-color:#0000!important;color:#fff!important}.zofSH .JjIFF{border:1px solid #db2828}.zofSH .RjCmg,.zofSH .JjIFF{background-color:#0000;color:#fff}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Spk1y`,
	"label": `raDjm`,
	"detailLink": `PH18A`,
	"detailMargin": `Sjs4O`,
	"detailHeader": `InJyV`,
	"jsonStrFmt": `DzDBM`,
	"message": `TYaT1`,
	"deleteMessage": `zofSH`,
	"deleteLabel": `JjIFF`,
	"deleteDetailLink": `RjCmg`
};
module.exports = ___CSS_LOADER_EXPORT___;
