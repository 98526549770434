import {
  SET_INVENTORY_FETCH_PENDING,
  SET_INVENTORY_FETCH_SUCCESS,
  SET_INVENTORY_FETCH_ERROR,
  SET_INVENTORY_VIEW_PROPERTIES,
} from '../actionTypes';

export default (
  state = {
    isFetchingInventory: false,
    fetchInventoryError: false,
    errorMsg: false,
    data: null,

    viewProps: {
      compliance: {
        notCompliant: true,
        compliant: true,
        notEvaluated: true,
      },
      vulnerabilities: {
        critical: true,
        high: true,
        medium: true,
        low: true,
        negligible: true,
        unknown: true,
      },
      mainTable: {
        filterStr: '',
        page: 0,
        pageSize: 10,
        pagePreserve: false,
        sort: {
          clusters: {
            id: 'cluster_name',
            desc: true,
          },
          namespaces: {
            id: 'imageCount',
            desc: true,
          },
          images: {
            id: 'lastAnalyzed',
            desc: true,
          },
          vulnerabilities: {
            id: 'severity',
            desc: true,
          },
        },
      },
    },
  },
  action = {},
) => {
  switch (action.type) {
    /**
     * *************************************************************************
     * INVENTORY VIEW REDUCERS
     */
    case SET_INVENTORY_VIEW_PROPERTIES:
      return {
        ...state,
        viewProps: action.viewProps,
      };

    /**
     * *************************************************************************
     * INVENTORY DATA REDUCERS
     */
    case SET_INVENTORY_FETCH_PENDING:
      return {
        ...state,
        isFetchingInventory: true,
      };
    case SET_INVENTORY_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingInventory: false,
        fetchInventoryError: false,
        data: action.resp.data,
      };
    case SET_INVENTORY_FETCH_ERROR:
      return {
        ...state,
        isFetchingInventory: false,
        fetchInventoryError: true,
        errorMsg: action.errorMsg,
      };

    default:
      return state;
  }
};
