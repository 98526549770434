import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Divider, Label, Message, Transition } from 'semantic-ui-react';

import s from './ErrMessage.scss';

/**
 * Example:
 *
 * <ErrMessage
 *   error={(addUserError && errorMsg) || !!error} // Required - must be the error object or a boolean. If
 *                                                 //            this evals to true, will show custom message
 *   message={error}                               // Optional - must be a boolean, string, or a React element
 *                                                 //            and will usually accompany flag set in `error`
 *                                                 //            prop above
 *   detail="More details can be shown here!"      // Optional - must be a boolean, string, or a React element
 *                                                 //            and upon being provided, will show the 'View
 *                                                 //            Details' button even w/o `showDetail` below
 *   className="animate__animated animate__shake"  // Optional - must be a string and defaults to `animate__animated
 *                                                 //            animate__fadeIn`
 *   messageProps={{ size: 'tiny' }}               // Optional - must be an object that deconstructs into props
 *                                                 //            used by Semantic's Message component
 *   showDetail                                    // Optional - must be a boolean and will provide a way to
 *                                                 //            pass through error details from the engine.
 *                                                 //            Ignored if detail prop has been supplied.
 *   onDismiss={this.props...}                     // Optional - must be a function. Called upon user clicking
 *                                                 //            the close icon provided along with this func
 *   deleteErr                                     // Optional - must be a boolean and will modify theme to be
 *                                                 //            compatible with delete modal style
 * />
 *
 * Note that besides `className` and `onDismiss`, all other props for Semantic's Message component should be
 * passed as an object to `messageProps`. `onDismiss` needed to be separated due to some styling conditionality
 * with the 'View Details' button/link.
 *
 */

class ErrMessage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { hideDetail: true };
  }

  /**
   * [toggleDetails description]
   * @return {[type]} [description]
   */
  toggleDetails = () => {
    this.setState(prevState => ({
      hideDetail: !prevState.hideDetail,
    }));
    return this;
  };

  /**
   * [render description]
   * @return {[type]} [description]
   */
  render() {
    const {
      error,
      message,
      detail,
      className,
      messageProps,
      showDetail,
      onDismiss,
      deleteErr,
    } = this.props;

    if (error) {
      const { hideDetail } = this.state;
      const { type, statusCode, message: msg } = error;

      let msgEl = false;
      let detEl = false;

      let isParsable;
      try {
        isParsable = JSON.parse(msg);
      } catch (e) {
        isParsable = false;
      }

      // If we provide a `message`, that takes priority
      if (message) {
        msgEl = message;
        // Use `showDetail` to show the original message within the detail section
        if (showDetail) {
          detEl = isParsable ? (
            <pre className={s.jsonStrFmt}>
              {JSON.stringify(isParsable, null, 2)}
            </pre>
          ) : (
            msg
          );
        }
      }

      // If we provide `detail`, that takes priority
      if (detail) {
        detEl = typeof detail === 'string' ? <p>{detail}</p> : detail;
      }

      // Fall back to the original response
      if (!msgEl) {
        if (isParsable) {
          const { message: parsedMsg, detail: parsedDetail } = isParsable;
          msgEl = parsedMsg;
          // Validate and assign details if supplied
          detEl =
            detEl ||
            (Object.keys(parsedDetail).length !== 0 ? (
              <pre className={s.jsonStrFmt}>
                {JSON.stringify(parsedDetail, null, 2)}
              </pre>
            ) : (
              false
            ));
        } else {
          // Otherwise, provide a default
          msgEl =
            msg ||
            `We're sorry but the operation failed.${
              detEl ? 'View details for more information.' : ''
            }`;
        }
      }

      return (
        <Message
          error={!deleteErr}
          color={deleteErr ? 'red' : null}
          className={classNames(
            className || 'animate__animated animate__fadeIn',
            deleteErr ? s.deleteMessage : '',
            s.root,
          )}
          onDismiss={onDismiss}
          {...messageProps}
        >
          <Label
            attached="top left"
            className={deleteErr ? s.deleteLabel : s.label}
          >
            {statusCode && `${statusCode} - `}
            {type || 'Error'}
          </Label>
          {typeof msgEl === 'string' ? (
            <p className={s.message}>{msgEl}</p>
          ) : (
            msgEl
          )}
          {detEl && (
            <Label
              attached="top right"
              className={classNames(
                s.detailLink,
                onDismiss ? s.detailMargin : '',
                deleteErr ? s.deleteDetailLink : '',
              )}
              onClick={this.toggleDetails}
              onKeyDown={({ key }) => key === 'Enter' && this.toggleDetails()}
            >
              {hideDetail ? 'View' : 'Hide'} Details
            </Label>
          )}
          <Transition.Group duration={300}>
            {!hideDetail && detEl && (
              <div>
                <Divider
                  horizontal
                  className={s.detailHeader}
                  inverted={deleteErr}
                >
                  Details
                </Divider>
                {detEl}
              </div>
            )}
          </Transition.Group>
        </Message>
      );
    }

    return null;
  }
}

export const propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  message: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.element,
  ]),
  detail: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.element,
  ]),
  className: PropTypes.string,
  messageProps: PropTypes.shape({}),
  showDetail: PropTypes.bool,
  onDismiss: PropTypes.func,
  deleteErr: PropTypes.bool,
};

ErrMessage.propTypes = propTypes;

ErrMessage.defaultProps = {
  message: false,
  detail: false,
  className: '',
  messageProps: {},
  showDetail: false,
  onDismiss: null,
  deleteErr: false,
};

export default withStyles(s)(ErrMessage);
